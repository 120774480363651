<template>
	<div class="healthEduData">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item prop="provinceDictionaryItem" label="省：">
					<el-select v-model="formInline.provinceDictionaryItem" placeholder="请选择省" @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="cityDictionaryItem" label="市：">
					<el-select v-model="formInline.cityDictionaryItem" placeholder="请选择市" @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="regionCode" label="区县：">
					<el-select v-model="formInline.regionCode" placeholder="请选择区县" @change="changeEconomize($event, 3)">
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="townCode" label="乡镇：">
					<el-select v-model="formInline.townCode" placeholder="请选择区县" @change="changeEconomize($event, 4)">
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="villageCode" label="村：">
					<el-select v-model="formInline.villageCode" placeholder="请选择区县" @change="changeEconomize($event, 5)">
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getData" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="container">
			<div class="healthEduData-title">
				<div class="healthEduData-title-content clearfix">
					<div class="institutionTotal float-l">
						<img src="@/assets/imgs/institution.png" alt="" />
						<div class="institutionTotal-content">
							<p>教育活动数</p>
							<p>{{ datas.activityNum || 0 }}</p>
						</div>
					</div>
					<div class="institutionTotal float-l mr0">
						<img src="@/assets/imgs/peoples.png" alt="" />
						<div class="institutionTotal-content">
							<p>受教育人数</p>
							<p>{{ datas.educationNum || 0 }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="content">
				<div class="chartsClass" ref="healthEduHeight">
					<p>
						<img src="@/assets/imgs/datas.png" alt="" />
						健康教育类别次数
					</p>
					<div
						class="nullData"
						:style="{ width: '100%', height: healthEduHeight + 'px', lineHeight: healthEduHeight + 'px' }"
						v-if="datas.healthNumTotal == 0"
					>
						暂无数据
					</div>
					<div id="healthEdu" :style="{ width: '100%', height: healthEduHeight + 'px' }" v-show="datas.healthNumTotal != 0"></div>
				</div>
				<div class="chartsClass" ref="titleChartsHeight">
					<p>
						<img src="@/assets/imgs/datas.png" alt="" />
						健康教育类别参与人数
					</p>
					<div
						class="nullData"
						:style="{ width: '100%', height: titleChartsHeight + 'px', lineHeight: titleChartsHeight + 'px' }"
						v-if="datas.healthPeopleNumTotal == 0"
					>
						暂无数据
					</div>
					<div id="titleCharts" :style="{ width: '100%', height: titleChartsHeight + 'px' }" v-show="datas.healthPeopleNumTotal != 0"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	name: 'healthEduData',
	data() {
		return {
			formInline: {
				provinceDictionaryItem: null,
				cityDictionaryItem: null,
				regionCode: null,
				townCode: null,
				villageCode: null,
			},
			townList: [], //乡镇
			villageList: [], //村
			districtList: [], //区县
			marketList: [], //市
			economizeList: [], //省
			healthEduHeight: 318, // 医护人员分类及人数统计图表高度
			titleChartsHeight: 318, // 职称分类及人数统计图表高度
			regionCode: '',
			datas: {},
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.healthEduHeight = this.$refs.healthEduHeight.offsetHeight - 90 > 318 ? this.$refs.healthEduHeight.offsetHeight - 90 : 318;
			this.titleChartsHeight = this.$refs.titleChartsHeight.offsetHeight - 90 > 318 ? this.$refs.titleChartsHeight.offsetHeight - 90 : 318;
		});
		this.getEconomizeList();
		this.getData();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api.getChinaRegions)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: null,
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			this.regionCode = data;
			switch (type) {
				case 1:
					this.formInline.cityDictionaryItem = null;
					this.formInline.distinguish = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.distinguish = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 4:
					this.formInline.villageCode = null;
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.villageList = res.data.collection || [];
								this.villageList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 查询条件
		getData() {
			let regionCode = this.formInline.villageCode ? this.formInline.villageCode : this.regionCode;
			this.$http
				.get(this.api.getTalentActivities, { params: { regionCode } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.datas = res.data.collection[0] || {};
						let data1 = res.data.collection[0].healthNum || [];
						let data2 = res.data.collection[0].healthPeopleNum || [];
						if (data1.length == 0) {
							this.datas.healthNumTotal = 0;
						} else {
							this.gethealthEduData(data1);
						}
						if (data2.length == 0) {
							this.datas.healthPeopleNumTotal = 0;
						} else {
							this.getTitleChartsData(data2);
						}
					}
				})
				.catch((e) => {});
		},
		// 健康教育类别次数
		gethealthEduData(data) {
			let myChart = echarts.init(document.getElementById('healthEdu'));
			myChart.setOption({
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					top: 20,
					left: 20,
					right: 40,
					bottom: 20,
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: data.map((item) => item.name),
					boundaryGap: false,
					splitLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E5E6EB',
						},
					},
					axisLabel: {
						margin: 10,
						interval: 0,
						textStyle: {
							fontSize: 12,
							color: '#666',
						},
						// rotate: 20,
						formatter: function (value, index) {
							var strs = value.split('');
							var str = '';
							for (var i = 0, s; (s = strs[i++]); ) {
								str += s;
								if (!(i % 6)) str += '\n';
							}
							return str;
						},
					},
				},
				yAxis: [
					{
						type: 'value',
						splitLine: {
							lineStyle: {
								type: 'dashed',
								color: '#E5E6EB',
							},
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#999',
							},
						},
						nameTextStyle: {
							color: '#666',
						},
						splitArea: {
							show: false,
						},
					},
				],
				series: [
					{
						// name: '课时',
						type: 'line',
						data: data.map((item) => item.intValue),
						symbolSize: 1,
						symbol: 'circle',
						smooth: true,
						yAxisIndex: 0,
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[
										{
											offset: 0,
											color: 'rgba(20,201,201,0.2)',
										},
										{
											offset: 1,
											color: 'rgba(20,201,201,0)',
										},
									],
									false
								),
							},
						},
						itemStyle: {
							normal: {
								color: '#f7b851',
							},
						},
						lineStyle: {
							normal: {
								width: 3,
								color: '#14C9C9',
							},
						},
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 获取健康教育类别参与人数
		getTitleChartsData(data) {
			let myChart = echarts.init(document.getElementById('titleCharts'));
			myChart.setOption({
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					top: 20,
					left: 20,
					right: 40,
					bottom: 20,
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					data: data.map((item) => item.name),
					boundaryGap: false,
					splitLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E5E6EB',
						},
					},
					axisLabel: {
						margin: 10,
						interval: 0,
						textStyle: {
							fontSize: 12,
							color: '#666',
						},
						formatter: function (value, index) {
							var strs = value.split('');
							var str = '';
							for (var i = 0, s; (s = strs[i++]); ) {
								str += s;
								if (!(i % 6)) str += '\n';
							}
							return str;
						},
					},
				},
				yAxis: [
					{
						type: 'value',
						splitLine: {
							lineStyle: {
								type: 'dashed',
								color: '#E5E6EB',
							},
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#999',
							},
						},
						nameTextStyle: {
							color: '#666',
						},
						splitArea: {
							show: false,
						},
					},
				],
				series: [
					{
						type: 'line',
						data: data.map((item) => item.intValue),
						symbolSize: 1,
						symbol: 'circle',
						smooth: true,
						yAxisIndex: 0,
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[
										{
											offset: 0,
											color: 'rgba(22,93,255,0.2)',
										},
										{
											offset: 1,
											color: 'rgba(22,93,255,0)',
										},
									],
									false
								),
							},
						},
						itemStyle: {
							normal: {
								color: '#f7b851',
							},
						},
						lineStyle: {
							normal: {
								width: 3,
								color: '#165DFF',
							},
						},
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.healthEduData {
	.container {
		margin: 16px;
	}
	.demo-form-inline {
		/deep/ .el-select {
			width: 106px !important;
			.el-input {
				width: 106px !important;
				.el-input__inner {
					width: 106px !important;
				}
			}
		}
		/deep/ .el-input {
			width: 106px;
		}
	}
	&-title {
		padding: 16px;
		background-color: #fff;
		border-radius: 4px;
		margin-bottom: 12px;

		&-content {
			.institutionTotal {
				display: flex;
				align-items: center;
				width: calc(50% - 6px);
				margin-right: 12px;
				background: #f9fafc;
				border-radius: 4px;
				padding: 16px;
				img {
					margin-right: 16px;
				}
				&-content {
					p {
						color: #999;
						font-size: 14px;
						height: 14px;
						line-height: 14px;
						&:last-child {
							font-size: 24px;
							line-height: 24px;
							height: 24px;
							margin-top: 12px;
							color: #333;
						}
					}
				}
			}
		}
	}
	.content {
		.chartsClass {
			background-color: #fff;
			margin-bottom: 12px;
			padding: 20px 16px;
			border-radius: 4px;
			&:last-child {
				margin-bottom: 0;
			}
			p {
				font-size: 16px;
				line-height: 28px;
				font-weight: 500;
				color: #333333;
				margin-bottom: 22px;
				display: flex;
				align-items: center;
				img {
					margin-right: 12px;
				}
			}
		}
	}
	.mr0 {
		margin-right: 0 !important;
	}
	.nullData {
		text-align: center;
		color: #999999;
		font-size: 20px;
	}
}
</style>